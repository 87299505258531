module.exports = [{
      plugin: require('/Users/coles/Documents/web-dev/react/purpleandbold/purpleandbold/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/coles/Documents/web-dev/react/purpleandbold/purpleandbold/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-133235986-1","head":false},
    },{
      plugin: require('/Users/coles/Documents/web-dev/react/purpleandbold/purpleandbold/node_modules/gatsby-remark-images/gatsby-browser'),
      options: {"plugins":[],"maxWidth":490,"wrapperStyle":"float: right; width: 50%; margin-right: 0; margin-top: 6em;","linkImagesToOriginal":false},
    },{
      plugin: require('/Users/coles/Documents/web-dev/react/purpleandbold/purpleandbold/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
